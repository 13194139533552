import React, { useState, useEffect, useCallback } from 'react';
import { Plus, Edit2, Trash2, AlertCircle } from 'lucide-react';
import { supabase } from '../lib/supabaseClient';
import TopNavigation from '../components/TopNavigation';

const Card = ({ children, className = '' }) => (
  <div className={`bg-white rounded-lg shadow-md p-4 md:p-6 ${className}`}>
    {children}
  </div>
);

const ActionButton = ({ children, icon: Icon, onClick, primary = false, disabled = false }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`flex items-center justify-center gap-2 px-3 py-2 md:px-4 md:py-2 rounded-full text-sm font-medium transition-colors ${
      primary
        ? 'bg-[#da018e] text-white hover:bg-[#b8017a]'
        : 'bg-gray-200 text-[#da018e] hover:bg-gray-300'
    } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
  >
    {Icon && <Icon className="w-4 h-4" />}
    {children}
  </button>
);

const Customize = () => {
  const [smoothies, setSmoothies] = useState([]);
  const [editingSmoothie, setEditingSmoothie] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notification, setNotification] = useState(null);

  const showNotification = useCallback((message, type = 'info') => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 5000);
  }, []);

  const fetchSmoothies = useCallback(async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('smoothies')
        .select('*')
        .order('name');
      
      if (error) throw error;
      setSmoothies(data);
    } catch (error) {
      console.error('Error fetching smoothies:', error);
      setError('Failed to load smoothies. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSmoothies();
  }, [fetchSmoothies]);

  const handleAddNewSmoothie = async (smoothieData) => {
    try {
      const { data, error } = await supabase
        .from('smoothies')
        .insert([smoothieData])
        .select();
      
      if (error) throw error;
      
      setSmoothies([...smoothies, data[0]]);
      setShowAddModal(false);
      showNotification('Smoothie added successfully', 'success');
    } catch (error) {
      console.error('Error adding smoothie:', error);
      showNotification('Failed to add smoothie. Please try again.', 'error');
    }
  };

  const handleEditSmoothie = (smoothie) => {
    setEditingSmoothie(smoothie);
  };

  const handleDeleteSmoothie = async (id) => {
    if (window.confirm('Are you sure you want to delete this smoothie?')) {
      try {
        const { error } = await supabase
          .from('smoothies')
          .delete()
          .eq('id', id);
        
        if (error) throw error;
        
        setSmoothies(smoothies.filter(s => s.id !== id));
        showNotification('Smoothie deleted successfully', 'success');
      } catch (error) {
        console.error('Error deleting smoothie:', error);
        showNotification('Failed to delete smoothie. Please try again.', 'error');
      }
    }
  };

  const handleSaveSmoothie = async (updatedSmoothie) => {
    try {
      const { data, error } = await supabase
        .from('smoothies')
        .upsert(updatedSmoothie)
        .select();
      
      if (error) throw error;
      
      setSmoothies(smoothies.map(s => s.id === data[0].id ? data[0] : s));
      setEditingSmoothie(null);
      showNotification('Smoothie updated successfully', 'success');
    } catch (error) {
      console.error('Error saving smoothie:', error);
      showNotification('Failed to save smoothie. Please try again.', 'error');
    }
  };

  const Modal = ({ title, onClose, onSubmit, initialData = { name: '', ingredients_small: '', ingredients_large: '' } }) => {
    const [formData, setFormData] = useState(initialData);
    const [formError, setFormError] = useState('');

    const handleSubmit = (e) => {
      e.preventDefault();
      if (!formData.name.trim()) {
        setFormError('Smoothie name is required');
        return;
      }
      onSubmit(formData);
    };

    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50" onClick={onClose}>
        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white" onClick={e => e.stopPropagation()}>
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">{title}</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={(e) => {
                  setFormData({...formData, name: e.target.value});
                  setFormError('');
                }}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-[#da018e] focus:border-[#da018e]"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="ingredients_small" className="block text-sm font-medium text-gray-700">Ingredients (Small)</label>
              <textarea
                id="ingredients_small"
                value={formData.ingredients_small}
                onChange={(e) => setFormData({...formData, ingredients_small: e.target.value})}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-[#da018e] focus:border-[#da018e]"
                rows="3"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="ingredients_large" className="block text-sm font-medium text-gray-700">Ingredients (Large)</label>
              <textarea
                id="ingredients_large"
                value={formData.ingredients_large}
                onChange={(e) => setFormData({...formData, ingredients_large: e.target.value})}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-[#da018e] focus:border-[#da018e]"
                rows="3"
                required
              />
            </div>
            {formError && <p className="text-red-500 text-sm mb-2">{formError}</p>}
            <div className="flex justify-end">
              <button
                type="button"
                onClick={onClose}
                className="mr-2 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#da018e]"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-[#da018e] border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-[#b8017a] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#da018e]"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100 flex justify-center items-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#da018e]"></div>
          <p className="mt-4 text-gray-600">Loading smoothies...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <TopNavigation />
      <div className="p-4 md:p-6 max-w-7xl mx-auto">
        <header className="flex justify-between items-center mb-6 md:mb-8">
          <h1 className="text-2xl md:text-3xl font-bold text-[#da018e]">Manage Smoothie Menu</h1>
          <ActionButton icon={Plus} primary onClick={() => setShowAddModal(true)}>
            Add New Smoothie
          </ActionButton>
        </header>

        {error && (
          <div className="mb-6 p-4 bg-red-100 border border-red-400 text-red-700 rounded-lg">
            {error}
          </div>
        )}

        <Card>
          <h2 className="text-xl font-bold text-gray-900 mb-4">Smoothie Menu</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Smoothie Name</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ingredients (Small)</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ingredients (Large)</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {smoothies.length === 0 ? (
                  <tr>
                    <td colSpan="4" className="px-6 py-4 text-center text-gray-500">No smoothies found</td>
                  </tr>
                ) : (
                  smoothies.map((smoothie) => (
                    <tr key={smoothie.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{smoothie.name}</td>
                      <td className="px-6 py-4 text-sm text-gray-500">{smoothie.ingredients_small}</td>
                      <td className="px-6 py-4 text-sm text-gray-500">{smoothie.ingredients_large}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <button 
                          onClick={() => handleEditSmoothie(smoothie)} 
                          className="text-[#da018e] hover:text-[#b8017a] mr-2"
                          aria-label="Edit smoothie"
                        >
                          <Edit2 className="w-5 h-5" />
                        </button>
                        <button 
                          onClick={() => handleDeleteSmoothie(smoothie.id)} 
                          className="text-red-600 hover:text-red-900"
                          aria-label="Delete smoothie"
                        >
                          <Trash2 className="w-5 h-5" />
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </Card>
      </div>

      {showAddModal && (
        <Modal
          title="Add New Smoothie"
          onClose={() => setShowAddModal(false)}
          onSubmit={handleAddNewSmoothie}
        />
      )}

      {editingSmoothie && (
        <Modal
          title="Edit Smoothie"
          onClose={() => setEditingSmoothie(null)}
          onSubmit={handleSaveSmoothie}
          initialData={editingSmoothie}
        />
      )}

      {notification && (
        <div className={`fixed bottom-4 right-4 p-4 rounded-lg shadow-lg ${
          notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'
        } text-white`}>
          {notification.message}
        </div>
      )}
    </div>
  );
};

export default Customize;

