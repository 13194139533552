import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ClerkProvider, SignedIn, SignedOut } from '@clerk/clerk-react';
import PickPlan from './components/PickPlan';
import CreateAccount from './components/CreateAccount';
import Dashboard from './pages/UserDashboard';
import Customize from './pages/Customize';
import Inventory from './pages/Inventory';
import Billing from './pages/Billing';
import SmoothieApp from './components/SmoothieApp';

const publishableKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

function App() {
  if (!publishableKey) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="max-w-md w-full space-y-8 p-6 bg-white rounded-xl shadow-md">
          <div className="text-center">
            <h2 className="text-xl font-semibold text-gray-900">Configuration Error</h2>
            <p className="mt-2 text-gray-600">
              Missing Clerk publishable key. Please check your environment configuration.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <ClerkProvider publishableKey={publishableKey}>
      <Router>
        <Switch>
          {/* Public Routes */}
          <Route exact path="/" component={PickPlan} />
          <Route path="/create-account" component={CreateAccount} />
          
          {/* Protected Routes */}
          <SignedIn>
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/add" component={Customize} />
            <Route path="/inventory" component={Inventory} />
            <Route path="/billing" component={Billing} />
            <Route path="/smoothies" component={SmoothieApp} />
          </SignedIn>

          {/* Redirect signed out users to home */}
          <SignedOut>
            <Route path="/smoothies">
              <Redirect to="/" />
            </Route>
            <Route path="/dashboard">
              <Redirect to="/" />
            </Route>
          </SignedOut>

          {/* Catch all route */}
          <Route path="*">
            <SignedIn>
              <Redirect to="/dashboard" />
            </SignedIn>
            <SignedOut>
              <Redirect to="/" />
            </SignedOut>
          </Route>
        </Switch>
      </Router>
    </ClerkProvider>
  );
}

export default App;

