import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Home, Coffee, Package, DollarSign, Settings, Menu, X } from 'lucide-react';
import { useClerk, UserButton } from '@clerk/clerk-react';

const NavItem = ({ to, icon: Icon, label, isActive }) => {
  return (
    <Link 
      to={to} 
      className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
        isActive 
          ? 'bg-[#da018e] text-white' 
          : 'text-gray-600 hover:bg-gray-100'
      }`}
      aria-current={isActive ? 'page' : undefined}
    >
      <Icon className="w-5 h-5 mr-3" />
      <span className="hidden md:inline">{label}</span>
    </Link>
  );
};

const TopNavigation = () => {
  const { signOut } = useClerk();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut();
      window.location.href = '/pick-plan';
    } catch (error) {
      console.error('Error during logout:', error);
      // You can add a toast notification here to inform the user about the error
    }
  };

  const navItems = [
    { to: '/dashboard', icon: Home, label: 'Dashboard' },
    { to: '/add', icon: Coffee, label: 'Customize' },
    { to: '/inventory', icon: Package, label: 'Inventory' },
    { to: '/billing', icon: DollarSign, label: 'Billing' },
    { to: '/smoothies', icon: Settings, label: 'Guide' },
  ];

  return (
    <nav className="bg-white shadow-md p-4 mb-6">
      <div className="flex justify-between items-center max-w-7xl mx-auto">
        <Link to="/dashboard" className="flex items-center">
          <img 
            src="https://upload.wikimedia.org/wikipedia/commons/6/6c/Planet_Smoothie_logo.svg" 
            alt="Planet Smoothie Logo" 
            className="h-12"
          /> 
        </Link>
        <div className="hidden md:flex flex-grow justify-center space-x-4">
          {navItems.map((item) => (
            <NavItem 
              key={item.to} 
              to={item.to} 
              icon={item.icon} 
              label={item.label} 
              isActive={location.pathname === item.to}
            />
          ))}
        </div>
        <div className="hidden md:block">
          <UserButton afterSignOutUrl="/pick-plan" />
        </div>
        <button 
          className="md:hidden"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          aria-expanded={isMobileMenuOpen}
          aria-label="Toggle menu"
        >
          {isMobileMenuOpen ? <X /> : <Menu />}
        </button>
      </div>
      {isMobileMenuOpen && (
        <div className="md:hidden mt-4">
          {navItems.map((item) => (
            <NavItem 
              key={item.to} 
              to={item.to} 
              icon={item.icon} 
              label={item.label} 
              isActive={location.pathname === item.to}
            />
          ))}
          <div className="px-4 py-2">
            <UserButton afterSignOutUrl="/pick-plan" />
          </div>
        </div>
      )}
    </nav>
  );
};

export default TopNavigation;

