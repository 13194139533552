import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Lock, ArrowLeft } from 'lucide-react';
import { useSignUp, useAuth } from '@clerk/clerk-react';
import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// SkeletonLoader Component
const SkeletonLoader = () => (
  <div className="animate-pulse">
    <div className="h-8 bg-gray-200 rounded w-1/4 mb-4"></div>
    <div className="h-6 bg-gray-200 rounded w-3/4 mb-6"></div>
    <div className="space-y-4 mb-6">
      <div className="h-10 bg-gray-200 rounded"></div>
      <div className="h-10 bg-gray-200 rounded"></div>
      <div className="h-10 bg-gray-200 rounded"></div>
    </div>
    <div className="h-12 bg-gray-200 rounded"></div>
  </div>
);

// Main CreateAccount Component
const CreateAccount = () => {
  const history = useHistory();
  const location = useLocation();
  const { isLoaded, signUp, setActive } = useSignUp();
  const { getToken } = useAuth();
  
  const [formData, setFormData] = useState({
    email: '',
    fullName: '',
    password: ''
  });
  const [verificationCode, setVerificationCode] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [pageLoading, setPageLoading] = useState(true);

  const [selectedPlan, setSelectedPlan] = useState(null);

  // Load selected plan from location state
  useEffect(() => {
    if (location.state?.selectedPlan) {
      setSelectedPlan(location.state.selectedPlan);
    } else {
      // If no plan is selected, redirect back to plan selection
      history.push('/');
    }
    const timer = setTimeout(() => setPageLoading(false), 1500);
    return () => clearTimeout(timer);
  }, [location.state, history]);

  // Handle initial sign up
  const handleInitialSignUp = async (e) => {
    e.preventDefault();
    if (!isLoaded) return;

    try {
      setIsLoading(true);
      setError('');

      const [firstName, ...lastNameParts] = formData.fullName.trim().split(' ');
      const lastName = lastNameParts.join(' ');

      await signUp.create({
        emailAddress: formData.email,
        password: formData.password,
        firstName,
        lastName: lastName || undefined
      });

      await signUp.prepareEmailAddressVerification({ strategy: "email_code" });
      setShowVerification(true);
    } catch (err) {
      console.error('Error during sign up:', err);
      setError(err.message || 'An error occurred during sign up');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle email verification
  const handleVerification = async (e) => {
    e.preventDefault();
    if (!isLoaded) return;

    try {
      setIsLoading(true);
      setError('');

      const result = await signUp.attemptEmailAddressVerification({
        code: verificationCode,
      });

      if (result.status === 'complete') {
        await setActive({ session: result.createdSessionId });
        setShowVerification(false);
        setShowPayment(true);
      } else {
        setError('Verification failed. Please try again.');
      }
    } catch (err) {
      console.error('Error during verification:', err);
      setError(err.message || 'Verification failed');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle checkout process
  const handleCheckout = async () => {
    setIsLoading(true);
    setError('');

    try {
      const stripe = await stripePromise;
      if (!stripe) throw new Error('Stripe failed to load');

      if (!selectedPlan || !selectedPlan.stripe_price_id) {
        throw new Error('No plan selected or price ID is missing. Please select a plan.');
      }

      const { error } = await stripe.redirectToCheckout({
        lineItems: [
          {
            price: selectedPlan.stripe_price_id,
            quantity: 1,
          },
        ],
        mode: 'subscription',
        successUrl: `${window.location.origin}/dashboard`,
        cancelUrl: `${window.location.origin}/canceled`,
        customerEmail: formData.email,
      });

      if (error) throw error;
    } catch (err) {
      console.error('Checkout error:', err);
      setError(err.message || 'An error occurred during checkout. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Render form based on current step
  const renderForm = () => {
    if (showPayment) {
      return (
        <div className="space-y-6">
          <p>You will be redirected to Stripe to complete your purchase.</p>
          <button
            onClick={handleCheckout}
            className="w-full py-2 px-4 rounded-lg bg-[#da018e] text-white font-semibold 
                     hover:bg-[#b8017a] transition duration-300 disabled:opacity-50"
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : 'Proceed to Checkout'}
          </button>
          {error && (
            <div className="p-2 bg-red-100 border border-red-400 text-red-700 rounded">
              {error}
            </div>
          )}
        </div>
      );
    }

    if (showVerification) {
      return (
        <form onSubmit={handleVerification} className="space-y-6">
          <div>
            <label className="block text-sm font-semibold mb-2">Verification Code</label>
            <input
              type="text"
              className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-[#da018e] focus:border-transparent"
              placeholder="Enter verification code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
            />
          </div>

          {error && (
            <div className="p-2 bg-red-100 border border-red-400 text-red-700 rounded">
              {error}
            </div>
          )}

          <div className="flex gap-4">
            <button 
              type="button"
              onClick={() => setShowVerification(false)}
              className="flex-1 py-2 px-4 rounded-lg border hover:bg-gray-50 transition duration-300"
            >
              Back
            </button>
            <button 
              type="submit"
              className="flex-1 py-2 px-4 rounded-lg bg-[#da018e] text-white font-semibold 
                       hover:bg-[#b8017a] transition duration-300 disabled:opacity-50"
              disabled={isLoading || !verificationCode}
            >
              {isLoading ? 'Verifying...' : 'Verify Email'}
            </button>
          </div>
        </form>
      );
    }

    return (
      <form onSubmit={handleInitialSignUp} className="space-y-6">
        <div>
          <label className="block text-sm font-semibold mb-2">Email</label>
          <input
            type="email"
            className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-[#da018e] focus:border-transparent"
            placeholder="you@example.com"
            value={formData.email}
            onChange={(e) => setFormData({...formData, email: e.target.value})}
            required
          />
        </div>

        <div>
          <label className="block text-sm font-semibold mb-2">Full Name</label>
          <input
            type="text"
            className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-pink-600 focus:border-transparent"
            placeholder="John Smith"
            value={formData.fullName}
            onChange={(e) => setFormData({...formData, fullName: e.target.value})}
            required
          />
        </div>

        <div>
          <label className="block text-sm font-semibold mb-2">Password</label>
          <input
            type="password"
            className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-pink-600 focus:border-transparent"
            placeholder="••••••••"
            value={formData.password}
            onChange={(e) => setFormData({...formData, password: e.target.value})}
            required
          />
          <p className="text-xs text-gray-500 mt-1">Must be at least 8 characters</p>
        </div>

        {error && (
          <div className="p-2 bg-red-100 border border-red-400 text-red-700 rounded">
            {error}
          </div>
        )}

        <div className="flex gap-4">
          <button 
            type="button"
            onClick={() => history.goBack()}
            className="flex-1 py-2 px-4 rounded-lg border hover:bg-gray-50 transition duration-300"
          >
            Cancel
          </button>
          <button 
            type="submit"
            className="flex-1 py-2 px-4 rounded-lg bg-[#da018e] text-white font-semibold 
                     hover:bg-[#b8017a] transition duration-300 disabled:opacity-50"
            disabled={isLoading || !formData.email || !formData.fullName || formData.password.length < 8}
          >
            {isLoading ? 'Creating Account...' : 'Create Account'}
          </button>
        </div>
      </form>
    );
  };

  if (pageLoading || !isLoaded || !selectedPlan) {
    return (
      <div className="min-h-screen bg-gray-100 flex flex-col items-center p-8 font-sans">
        <div className="w-full max-w-5xl">
          <div className="flex justify-center gap-8 mb-8">
            <div className="w-1/2 bg-white rounded-lg shadow-lg p-6">
              <SkeletonLoader />
            </div>
            <div className="w-1/2 bg-white rounded-lg shadow-lg p-6">
              <SkeletonLoader />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center p-8 font-sans">
      <div className="w-full max-w-5xl">
        <button 
          onClick={() => {
            if (showPayment) {
              setShowPayment(false);
              setShowVerification(true);
            } else if (showVerification) {
              setShowVerification(false);
            } else {
              history.goBack();
            }
          }}
          className="mb-6 flex items-center text-gray-600 hover:text-gray-800 transition-colors"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          {showPayment ? 'Back to Verification' : showVerification ? 'Back to Sign Up' : 'Back to Plans'}
        </button>

        <div className="flex justify-center gap-8 mb-8">
          {/* Left column - Form */}
          <div className="w-1/2 bg-white rounded-lg shadow-lg p-6">
            <div className="flex items-center gap-2 mb-4">
              <div className="w-8 h-8 bg-[#da018e] rounded-full flex items-center justify-center text-white font-bold">
                PS
              </div>
              <span className="text-lg font-medium">Planet Smoothie</span>
            </div>

            <h1 className="text-xl font-bold mb-1">
              {showPayment 
                ? 'Payment Details' 
                : showVerification 
                  ? 'Verify your email' 
                  : `Upgrade to ${selectedPlan.name}`}
            </h1>
            <p className="text-sm text-gray-600 mb-6">
              {showPayment 
                ? `Complete your subscription to ${selectedPlan.name}`
                : showVerification 
                  ? `We've sent a verification code to ${formData.email}`
                  : 'Get started with your Planet Smoothie membership today'}
            </p>

            {renderForm()}

            {/* Security information */}
            <div className="mt-6 pt-6 border-t">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Lock size={12} className="text-gray-400" />
                  <span className="text-xs text-gray-500">
                    {showPayment ? 'Secure payment' : 'Secure sign up'}
                  </span>
                </div>
              </div>
              <p className="text-xs text-gray-500 mt-2">
                {showPayment 
                  ? 'Your payment information is processed securely. We do not store credit card details.'
                  : 'Your information is processed securely. We prioritize the protection of your personal data.'}
              </p>
            </div>
          </div>

          {/* Right column - Plan details */}
          <div className="w-1/2 bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-lg font-bold mb-6">{selectedPlan.name}</h2>
            
            {/* Total price */}
            <div className="border-t pt-4">
              <div className="flex justify-between items-center mb-4">
                <span className="font-bold">Total</span>
                <span className="font-bold">
                  ${selectedPlan.price}/Month
                </span>
              </div>
              
              <div className="flex items-center gap-2 text-sm text-gray-600">
                <Lock size={16} />
                <span>
                  Guaranteed to be safe & secure, ensuring that all transactions are 
                  protected with the highest level of security
                </span>
              </div>
            </div>

            {/* Plan features */}
            <div className="mt-4 border-t pt-4">
              <h3 className="font-bold mb-2">Plan Features:</h3>
              <ul className="space-y-2">
                {selectedPlan.features.map((feature, index) => (
                  <li key={index} className="flex items-center gap-2">
                    <span className="text-[#da018e]">✔</span>
                    <span className="text-sm text-gray-600">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>

            {/* FAQs */}
            <div className="mt-6 pt-4 border-t">
              <h3 className="font-bold mb-2">Frequently Asked Questions</h3>
              <div className="space-y-4">
                <div>
                  <h4 className="font-semibold">Can I cancel my subscription?</h4>
                  <p className="text-sm text-gray-600">Yes, you can cancel your subscription at any time.</p>
                </div>
                <div>
                  <h4 className="font-semibold">How often will I receive smoothies?</h4>
                  <p className="text-sm text-gray-600">Smoothies are delivered weekly based on your chosen plan.</p>
                </div>
                <div>
                  <h4 className="font-semibold">Are there any commitments?</h4>
                  <p className="text-sm text-gray-600">No long-term commitments. You can change or cancel your plan anytime.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;

