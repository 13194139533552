import React, { useState, useEffect, useCallback } from 'react';
import { Download, AlertCircle, ArrowUpRight } from 'lucide-react';
import { supabase } from '../lib/supabaseClient';
import { loadStripe } from '@stripe/stripe-js';
import TopNavigation from '../components/TopNavigation';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// Card component (unchanged)
const Card = ({ children, className = '' }) => (
  <div className={`bg-white rounded-lg shadow-md p-4 md:p-6 ${className}`}>
    {children}
  </div>
);

// ActionButton component (unchanged)
const ActionButton = ({ children, icon: Icon, onClick, primary = false, disabled = false }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`flex items-center justify-center gap-2 px-3 py-2 md:px-4 md:py-2 rounded-full text-sm font-medium transition-colors ${
      primary
        ? 'bg-[#da018e] text-white hover:bg-[#b8017a]'
        : 'bg-gray-200 text-[#da018e] hover:bg-gray-300'
    } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
  >
    {Icon && <Icon className="w-4 h-4" />}
    {children}
  </button>
);

const Billing = () => {
  const [subscription, setSubscription] = useState(null);
  const [billingHistory, setBillingHistory] = useState([]);
  const [paperlessBilling, setPaperlessBilling] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notification, setNotification] = useState(null);

  const showNotification = useCallback((message, type = 'info') => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 5000);
  }, []);

  const fetchBillingData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        // Fetch subscription data
        const { data: subData, error: subError } = await supabase
          .from('subscriptions')
          .select('*')
          .eq('user_id', user.id)
          .single();
        if (subError) throw subError;
        setSubscription(subData);

        // Fetch billing history
        const { data: history, error: historyError } = await supabase
          .from('billing_history')
          .select('*')
          .eq('user_id', user.id)
          .order('date', { ascending: false });
        if (historyError) throw historyError;
        setBillingHistory(history || []);

        // Fetch user preferences
        const { data: preferences, error: prefError } = await supabase
          .from('user_preferences')
          .select('paperless_billing')
          .eq('user_id', user.id)
          .single();
        if (prefError) throw prefError;
        setPaperlessBilling(preferences?.paperless_billing || false);
      }
    } catch (error) {
      console.error('Error fetching billing data:', error);
      setError('Failed to load billing information. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchBillingData();
  }, [fetchBillingData]);

  const handleChangePlan = async () => {
    try {
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        items: [{ plan: 'price_1234', quantity: 1 }],
        successUrl: window.location.origin + '/billing?success=true',
        cancelUrl: window.location.origin + '/billing?canceled=true',
      });
      if (error) throw error;
    } catch (error) {
      console.error('Error changing plan:', error);
      showNotification('Failed to change plan. Please try again later.', 'error');
    }
  };

  const togglePaperlessBilling = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const newValue = !paperlessBilling;
      const { error } = await supabase
        .from('user_preferences')
        .upsert({ user_id: user.id, paperless_billing: newValue });
      if (error) throw error;
      setPaperlessBilling(newValue);
      showNotification('Paperless billing preference updated successfully', 'success');
    } catch (error) {
      console.error('Error toggling paperless billing:', error);
      showNotification('Failed to update paperless billing preference. Please try again.', 'error');
    }
  };

  const getSubscriptionDetails = useCallback(() => {
    if (!subscription) return null;
    const planDetails = {
      'basic': { price: 9.99, features: ['1 Smoothie per day', 'Basic flavors', 'Email support'] },
      'premium': { price: 19.99, features: ['3 Smoothies per day', 'Premium flavors', '24/7 support'] },
      'family': { price: 29.99, features: ['5 Smoothies per day', 'All flavors', 'Priority support'] },
    };
    return { ...planDetails[subscription.plan_name], name: subscription.plan_name };
  }, [subscription]);

  const handleDownloadHistory = () => {
    // Implementation for downloading billing history
    console.log('Downloading billing history...');
    showNotification('Billing history download started', 'success');
  };

  const planDetails = getSubscriptionDetails();

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100 flex justify-center items-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#da018e]"></div>
          <p className="mt-4 text-gray-600">Loading billing information...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <TopNavigation />
      <div className="p-4 md:p-6 max-w-7xl mx-auto">
        <header className="flex justify-between items-center mb-6 md:mb-8">
          <h1 className="text-2xl md:text-3xl font-bold text-[#da018e]">Billing</h1>
          <ActionButton icon={Download} onClick={handleDownloadHistory}>Download History</ActionButton>
        </header>

        {error && (
          <div className="mb-6 p-4 bg-red-100 border border-red-400 text-red-700 rounded-lg">
            {error}
          </div>
        )}

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 md:gap-8">
          <div className="lg:col-span-2 space-y-6">
            <Card>
              <h2 className="text-xl font-bold text-gray-900 mb-4">Current Subscription</h2>
              {planDetails ? (
                <div>
                  <div className="flex justify-between items-center mb-4">
                    <div>
                      <p className="text-xl font-bold text-gray-900">{planDetails.name}</p>
                      <p className="text-[#da018e]">${planDetails.price}/month</p>
                    </div>
                    <ActionButton onClick={handleChangePlan} icon={ArrowUpRight} primary>
                      Change Plan
                    </ActionButton>
                  </div>
                  <ul className="space-y-2">
                    {planDetails.features?.map((feature, index) => (
                      <li key={index} className="flex items-center gap-2">
                        <AlertCircle className="w-5 h-5 text-[#da018e]" />
                        <span className="text-gray-700">{feature}</span>
                      </li>
                    ))}
                  </ul>
                  <p className="mt-4 text-sm text-gray-500">
                    Your subscription will renew on {new Date(subscription.current_period_end).toLocaleDateString()}
                  </p>
                </div>
              ) : (
                <p className="text-gray-600">No active subscription</p>
              )}
            </Card>

            <Card>
              <h2 className="text-xl font-bold text-gray-900 mb-4">Billing History</h2>
              <div className="overflow-x-auto">
                <table className="min-w-full">
                  <thead className="text-left text-gray-500 border-b">
                    <tr>
                      <th className="pb-2 font-medium">Date</th>
                      <th className="pb-2 font-medium">Amount</th>
                      <th className="pb-2 font-medium">Description</th>
                      <th className="pb-2 font-medium">Status</th>
                      <th className="pb-2 font-medium">Invoice</th>
                    </tr>
                  </thead>
                  <tbody>
                    {billingHistory.length === 0 ? (
                      <tr>
                        <td colSpan="5" className="py-4 text-center text-gray-500">No billing history available</td>
                      </tr>
                    ) : (
                      billingHistory.map((item) => (
                        <tr key={item.id} className="border-b last:border-b-0">
                          <td className="py-3 text-gray-900">{new Date(item.date).toLocaleDateString()}</td>
                          <td className="py-3 text-gray-900">${item.amount.toFixed(2)}</td>
                          <td className="py-3 text-gray-900">{item.description}</td>
                          <td className="py-3 text-gray-900 capitalize">{item.status}</td>
                          <td className="py-3">
                            <button 
                              className="text-[#da018e] hover:text-[#b8017a] font-medium"
                              onClick={() => showNotification('Invoice download started', 'success')}
                            >
                              Download
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </Card>
          </div>

          <div className="space-y-6">
            <Card>
              <h2 className="text-xl font-bold text-gray-900 mb-4">Billing Settings</h2>
              <div className="flex items-center justify-between">
                <div>
                  <p className="font-medium text-gray-900">Paperless Billing</p>
                  <p className="text-sm text-gray-500">Receive invoices via email</p>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={paperlessBilling}
                    onChange={togglePaperlessBilling}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#da018e] rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#da018e]"></div>
                </label>
              </div>
            </Card>

            <Card>
              <h2 className="text-xl font-bold text-gray-900 mb-4">Need Help?</h2>
              <p className="text-gray-600 mb-4">
                If you have any questions about your subscription, our support team is here to help.
              </p>
              <ActionButton onClick={() => showNotification('Support request sent', 'success')}>
                Contact Support
              </ActionButton>
            </Card>

            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-lg">
              <div className="flex">
                <AlertCircle className="text-yellow-400 mr-2" />
                <p className="text-sm text-yellow-700">
                  Your next billing date is{' '}
                  <span className="font-bold">
                    {subscription?.next_billing_date
                      ? new Date(subscription.next_billing_date).toLocaleDateString()
                      : 'Not available'}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {notification && (
        <div className={`fixed bottom-4 right-4 p-4 rounded-lg shadow-lg ${
          notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'
        } text-white`}>
          {notification.message}
        </div>
      )}
    </div>
  );
};

export default Billing;

