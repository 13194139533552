import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Info, ArrowLeft } from 'lucide-react';
import { Dialog } from '@headlessui/react';

// PlanCard component
const PlanCard = ({ name, price, features, isPopular, onSelect, isSelected, isLoading }) => (
  <div
    className={`bg-white rounded-lg p-6 shadow-lg relative ${
      isPopular ? 'border-2 border-[#da018e]' : ''
    } ${isSelected ? 'ring-2 ring-[#da018e]' : ''}`}
  >
    {isPopular && (
      <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-[#da018e] text-white px-4 py-1 rounded-full text-sm font-medium">
        Most Popular
      </div>
    )}
    <h3 className="text-lg font-bold mb-2">{name}</h3>
    <p className="text-3xl font-bold text-gray-800 mb-4">${price}/mo</p>
    <ul className="space-y-3 mb-6">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center gap-2">
          <span className="text-[#da018e]">✔</span>
          {feature}
        </li>
      ))}
    </ul>
    <button
      onClick={onSelect}
      disabled={isLoading}
      className={`w-full bg-[#da018e] text-white py-2 px-4 rounded-lg font-semibold transition duration-300 hover:bg-[#b8017a] ${
        isLoading ? 'opacity-50 cursor-not-allowed' : ''
      }`}
      aria-label={`Select ${name} plan`}
    >
      {isSelected ? 'Selected' : isLoading ? 'Processing...' : 'Select Plan'}
    </button>
  </div>
);

// SkeletonLoader component
const SkeletonLoader = () => (
  <div className="bg-white rounded-lg p-6 shadow-lg animate-pulse">
    <div className="h-6 bg-gray-200 rounded mb-4"></div>
    <div className="h-8 bg-gray-200 rounded mb-4"></div>
    <div className="space-y-3 mb-6">
      {[1, 2, 3].map((i) => (
        <div key={i} className="h-4 bg-gray-200 rounded"></div>
      ))}
    </div>
    <div className="h-10 bg-gray-200 rounded"></div>
  </div>
);

// Main PickPlan component
const PickPlan = () => {
  const history = useHistory();
  const [plansLoading, setPlansLoading] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [error, setError] = useState(null);
  const [plans, setPlans] = useState([]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [planToConfirm, setPlanToConfirm] = useState(null);

  // Fetch plans on component mount
  useEffect(() => {
    const fetchPlans = async () => {
      setPlansLoading(true);
      try {
        // Simulate API delay
        await new Promise(resolve => setTimeout(resolve, 1500));
        // Set mock plans data
        setPlans([
          {
            id: 1,
            name: "Basic Plan",
            price: 29,
            features: ["5 Smoothies per week", "Basic flavors", "Email support"],
            stripe_price_id: "price_1QKo5HHn9X1Cu7esffIHo311",
            isPopular: false
          },
          {
            id: 2,
            name: "Pro Plan",
            price: 49,
            features: ["10 Smoothies per week", "Premium flavors", "24/7 support", "Custom recipes"],
            stripe_price_id: "price_1QKoDiHn9X1Cu7es0CdSIfZP",
            isPopular: true
          },
          {
            id: 3,
            name: "Enterprise",
            price: 99,
            features: ["Unlimited Smoothies", "All flavors", "Priority support", "Custom branding"],
            stripe_price_id: "price_3EfGhIjKlMnOpQrS",
            isPopular: false
          }
        ]);
      } catch (error) {
        setError("Failed to load plans. Please try again later.");
      } finally {
        setPlansLoading(false);
      }
    };

    fetchPlans();
  }, []);

  // Handle plan selection
  const handlePlanSelect = (plan) => {
    setPlanToConfirm(plan);
    setIsConfirmationOpen(true);
  };

  // Handle plan confirmation
  const handleConfirmPlan = () => {
    setSelectedPlan(planToConfirm);
    setIsConfirmationOpen(false);
    // Navigate to create account page with selected plan data
    history.push('/create-account', { selectedPlan: planToConfirm });
  };

  // Render error state
  if (error) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full text-center">
          <p className="text-red-600">{error}</p>
          <button
            onClick={() => setError(null)}
            className="mt-4 bg-[#da018e] text-white py-2 px-4 rounded-lg font-semibold hover:bg-[#b8017a] transition"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 text-black font-sans">
      <div className="max-w-5xl mx-auto px-4 py-12">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold mb-4">Choose Your Smoothie Plan</h1>
          <p className="text-lg text-gray-600 mb-4">Enjoy delicious, healthy smoothies with our subscription plans.</p>
          <div className="flex items-center justify-center text-sm text-gray-500">
            <Info size={16} className="mr-2" />
            <span>All plans include a 7-day free trial. Cancel anytime.</span>
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {plansLoading
            ? [1, 2, 3].map((i) => <SkeletonLoader key={i} />)
            : plans.map((plan) => (
                <PlanCard
                  key={plan.id}
                  {...plan}
                  isSelected={selectedPlan && selectedPlan.id === plan.id}
                  onSelect={() => handlePlanSelect(plan)}
                />
              ))
          }
        </div>

        {/* Why Choose Planet Smoothie section */}
        <div className="mt-12 text-center">
          <h2 className="text-2xl font-bold mb-4">Why Choose Planet Smoothie?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left">
            <div className="bg-white p-6 rounded-lg shadow">
              <h3 className="font-bold mb-2">Fresh Ingredients</h3>
              <p>We use only the freshest, locally-sourced ingredients in our smoothies.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow">
              <h3 className="font-bold mb-2">Customizable</h3>
              <p>Tailor your smoothies to your taste preferences and dietary needs.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow">
              <h3 className="font-bold mb-2">Convenient Delivery</h3>
              <p>Enjoy your smoothies delivered right to your doorstep.</p>
            </div>
          </div>
        </div>

        {/* Back to Home link */}
        <div className="mt-12 text-center">
          <Link to="/" className="inline-flex items-center text-[#da018e] hover:underline">
            <ArrowLeft size={16} className="mr-2" />
            Back to Home
          </Link>
        </div>

        {/* Confirmation Dialog */}
        <Dialog open={isConfirmationOpen} onClose={() => setIsConfirmationOpen(false)}>
          <div className="fixed inset-0 bg-black bg-opacity-25" />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Confirm Your Plan
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Are you sure you want to select the {planToConfirm?.name} for ${planToConfirm?.price}/mo?
                  </p>
                </div>
                <div className="mt-4 flex justify-end space-x-2">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                    onClick={() => setIsConfirmationOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-[#da018e] px-4 py-2 text-sm font-medium text-white hover:bg-[#b8017a] focus:outline-none focus-visible:ring-2 focus-visible:ring-[#da018e] focus-visible:ring-offset-2"
                    onClick={handleConfirmPlan}
                  >
                    Confirm
                  </button>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default PickPlan;

