import React, { useState, useEffect } from 'react';
import { AlertCircle, Search, Plus, Edit2, Trash2, MessageSquare } from 'lucide-react';
import { supabase } from '../lib/supabaseClient';
import TopNavigation from '../components/TopNavigation';
import { debounce } from 'lodash';

// Constants for application settings
const ITEMS_PER_PAGE = 10;
const LOW_STOCK_THRESHOLD = 5;
const INITIAL_SORT = { key: 'name', direction: 'asc' };

// Reusable Card component
const Card = ({ children, className = '' }) => (
  <div className={`bg-white rounded-lg shadow-md p-4 md:p-6 ${className}`}>
    {children}
  </div>
);

// Reusable Button component
const ActionButton = ({ children, icon: Icon, onClick, primary = false, disabled = false }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`flex items-center justify-center gap-2 px-3 py-2 md:px-4 md:py-2 rounded-full 
                text-sm font-medium transition-colors 
                ${primary 
                  ? 'bg-[#da018e] text-white hover:bg-[#b8017a]' 
                  : 'bg-gray-200 text-[#da018e] hover:bg-gray-300'} 
                ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
  >
    {Icon && <Icon className="w-4 h-4" />}
    {children}
  </button>
);

// Modal component for adding/editing ingredients
const IngredientModal = ({ isOpen, onClose, onSubmit, initialData = null }) => {
  const [formData, setFormData] = useState(initialData || {
    name: '',
    quantity: 0,
    minimum_stock: LOW_STOCK_THRESHOLD
  });

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">
          {initialData ? 'Edit Ingredient' : 'Add New Ingredient'}
        </h2>
        <form onSubmit={(e) => {
          e.preventDefault();
          onSubmit(formData);
        }}>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#da018e] focus:ring-[#da018e]"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Quantity</label>
              <input
                type="number"
                value={formData.quantity}
                onChange={(e) => setFormData({ ...formData, quantity: parseInt(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#da018e] focus:ring-[#da018e]"
                required
                min="0"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Minimum Stock Level</label>
              <input
                type="number"
                value={formData.minimum_stock}
                onChange={(e) => setFormData({ ...formData, minimum_stock: parseInt(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#da018e] focus:ring-[#da018e]"
                required
                min="0"
              />
            </div>
          </div>
          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-[#da018e] text-white rounded-md text-sm font-medium hover:bg-[#b8017a]"
            >
              {initialData ? 'Save Changes' : 'Add Ingredient'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const Inventory = () => {
  // State management
  const [ingredients, setIngredients] = useState([]);
  const [lowStockItems, setLowStockItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [sortConfig, setSortConfig] = useState(INITIAL_SORT);
  const [editingId, setEditingId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notification, setNotification] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  /**
   * Main function to fetch ingredients from Supabase
   * Handles pagination, sorting, and search
   */
  // Updated fetchIngredients function for Inventory.js
const fetchIngredients = async () => {
  setIsLoading(true);
  setError(null);
  
  try {
    // Build basic query similar to Customize.js
    let query = supabase
      .from('ingredients')
      .select('*');
    
    // Add search if present
    if (searchTerm) {
      query = query.ilike('name', `%${searchTerm}%`);
    }
    
    // Add ordering
    query = query.order(sortConfig.key, {
      ascending: sortConfig.direction === 'asc'
    });

    const { data, error, count } = await query;

    if (error) {
      console.error('Supabase error:', error);
      throw error;
    }

    // Handle pagination on the client side instead
    const start = (currentPage - 1) * ITEMS_PER_PAGE;
    const paginatedData = data.slice(start, start + ITEMS_PER_PAGE);
    
    setIngredients(paginatedData);
    setTotalPages(Math.ceil(data.length / ITEMS_PER_PAGE));
    updateLowStockItems(paginatedData);
    
  } catch (err) {
    console.error('Error fetching ingredients:', err);
    setError('Failed to fetch ingredients. Please try again.');
  } finally {
    setIsLoading(false);
  }
};

  /**
   * Effect hook to fetch ingredients when dependencies change
   */
  useEffect(() => {
    let isMounted = true;

    const loadIngredients = async () => {
      if (!isMounted) return;
      await fetchIngredients();
    };

    loadIngredients();

    // Cleanup function to prevent memory leaks
    return () => {
      isMounted = false;
    };
  }, [searchTerm, sortConfig, currentPage]);

  /**
   * Handle adding a new ingredient
   * @param {Object} ingredientData - The new ingredient data
   */
  const handleAddIngredient = async (ingredientData) => {
    try {
      const { data, error } = await supabase
        .from('ingredients')
        .insert([{
          ...ingredientData,
          last_updated: new Date().toISOString()
        }])
        .select();

      if (error) throw error;

      setIngredients(prev => [...prev, data[0]]);
      showNotification('Ingredient added successfully', 'success');
      setShowAddModal(false);
    } catch (err) {
      console.error('Error adding ingredient:', err);
      showNotification('Failed to add ingredient', 'error');
    }
  };

  /**
   * Handle updating an existing ingredient
   * @param {string} id - Ingredient ID
   * @param {string} field - Field to update
   * @param {any} value - New value
   */
  const handleUpdateIngredient = async (id, field, value) => {
    try {
      const { error } = await supabase
        .from('ingredients')
        .update({ 
          [field]: value,
          last_updated: new Date().toISOString()
        })
        .eq('id', id);

      if (error) throw error;

      setIngredients(prev => 
        prev.map(ing => ing.id === id ? { ...ing, [field]: value } : ing)
      );
      
      showNotification('Ingredient updated successfully', 'success');
    } catch (err) {
      console.error('Error updating ingredient:', err);
      showNotification('Failed to update ingredient', 'error');
    }
  };

  /**
   * Handle deleting an ingredient
   * @param {string} id - Ingredient ID
   */
  const handleDeleteIngredient = async (id) => {
    if (!window.confirm('Are you sure you want to delete this ingredient?')) return;

    try {
      const { error } = await supabase
        .from('ingredients')
        .delete()
        .eq('id', id);

      if (error) throw error;

      setIngredients(prev => prev.filter(ing => ing.id !== id));
      showNotification('Ingredient deleted successfully', 'success');
    } catch (err) {
      console.error('Error deleting ingredient:', err);
      showNotification('Failed to delete ingredient', 'error');
    }
  };

  /**
   * Update low stock items list
   * @param {Array} items - Current ingredients list
   */
  const updateLowStockItems = (items) => {
    const lowStock = items.filter(item => item.quantity < LOW_STOCK_THRESHOLD);
    setLowStockItems(lowStock);
  };

  /**
   * Handle search with debounce
   */
  const handleSearch = debounce((e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on new search
  }, 300);

  /**
   * Display notification
   * @param {string} message - Notification message
   * @param {string} type - Notification type (success/error)
   */
  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  // Loading state
  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-100 flex justify-center items-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#da018e]"></div>
          <p className="mt-4 text-gray-600">Loading ingredients...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <TopNavigation />
      <div className="p-4 md:p-6 max-w-7xl mx-auto">
        <header className="flex justify-between items-center mb-6 md:mb-8">
          <h1 className="text-2xl md:text-3xl font-bold text-[#da018e]">Inventory Management</h1>
          <ActionButton 
            icon={Plus} 
            primary 
            onClick={() => setShowAddModal(true)}
          >
            Add Item
          </ActionButton>
        </header>

        <div className="mb-6 md:mb-8">
          <div className="relative">
            <input
              type="text"
              placeholder="Search ingredients..."
              onChange={handleSearch}
              className="pl-10 pr-4 py-2 w-full border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-[#da018e] focus:border-transparent"
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 md:gap-8">
          <div className="lg:col-span-2">
            <Card>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th 
                        scope="col" 
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                        onClick={() => setSortConfig({
                          key: 'name',
                          direction: sortConfig.key === 'name' && sortConfig.direction === 'asc' ? 'desc' : 'asc'
                        })}
                      >
                        Name
                        {sortConfig.key === 'name' && (
                          <span className="ml-1">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>
                        )}
                      </th>
                      <th 
                        scope="col" 
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                        onClick={() => setSortConfig({
                          key: 'quantity',
                          direction: sortConfig.key === 'quantity' && sortConfig.direction === 'asc' ? 'desc' : 'asc'
                        })}
                      >
                        Quantity
                        {sortConfig.key === 'quantity' && (
                          <span className="ml-1">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>
                        )}
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Last Updated
                      </th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {ingredients.map((ingredient) => (
                      <tr key={ingredient.id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {editingId === ingredient.id ? (
                            <input
                              type="text"
                              value={ingredient.name}
                              onChange={(e) => handleUpdateIngredient(ingredient.id, 'name', e.target.value)}
                              className="w-full p-1 border rounded"
                            />
                          ) : (
                            <span className="text-sm font-medium text-gray-900">{ingredient.name}</span>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {editingId === ingredient.id ? (
                            <input
                              type="number"
                              value={ingredient.quantity}
                              onChange={(e) => handleUpdateIngredient(ingredient.id, 'quantity', parseInt(e.target.value))}
                              className="w-20 p-1 border rounded"
                              min="0"
                            />
                          ) : (
                            <span className={`text-sm ${ingredient.quantity < LOW_STOCK_THRESHOLD ? 'text-red-600' : 'text-gray-500'}`}>
                              {ingredient.quantity}
                            </span>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {new Date(ingredient.last_updated).toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
                          <button
                            onClick={() => setEditingId(editingId === ingredient.id ? null : ingredient.id)}
                            className="text-[#da018e] hover:text-[#b8017a]"
                          >
                            <Edit2 className="w-4 h-4 inline" />
                          </button>
                          <button
                            onClick={() => handleDeleteIngredient(ingredient.id)}
                            className="text-red-600 hover:text-red-900"
                          >
                            <Trash2 className="w-4 h-4 inline" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* Pagination */}
              <div className="mt-4 flex justify-between items-center">
                <span className="text-sm text-gray-700">
                  Page {currentPage} of {totalPages}
                </span>
                <div className="space-x-2">
                  <button
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                    className="px-3 py-1 border rounded text-sm disabled:opacity-50"
                  >
                    Previous
                  </button>
                  <button
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                    className="px-3 py-1 border rounded text-sm disabled:opacity-50"
                  >
                    Next
                  </button>
                </div>
              </div>
            </Card>
          </div>

          {/* Right Sidebar */}
          <div className="space-y-6">
            {/* Low Stock Alert */}
            <Card>
              <h2 className="text-xl font-bold text-gray-900 mb-4">Low Stock Alert</h2>
              {lowStockItems.length === 0 ? (
                <p className="text-gray-500">No low stock items</p>
              ) : (
                <ul className="space-y-3">
                  {lowStockItems.map((item) => (
                    <li key={item.id} className="flex items-center justify-between p-3 bg-red-50 rounded-lg">
                      <span className="font-medium text-red-700">{item.name}</span>
                      <span className="text-sm text-red-600">{item.quantity} left</span>
                    </li>
                  ))}
                </ul>
              )}
            </Card>

            {/* Quick Actions */}
            <Card>
              <h2 className="text-xl font-bold text-gray-900 mb-4">Quick Actions</h2>
              <div className="space-y-3">
                <ActionButton 
                  icon={MessageSquare} 
                  onClick={() => showNotification('Notifications sent to suppliers', 'success')}
                  disabled={lowStockItems.length === 0}
                >
                  Notify Suppliers
                </ActionButton>
              </div>
            </Card>

            {/* Tips Section */}
            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-lg">
              <div className="flex">
                <AlertCircle className="text-yellow-400 mr-2" />
                <p className="text-sm text-yellow-700">
                  <span className="font-bold">Tip:</span> Regularly update your inventory to ensure accurate stock levels.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add/Edit Modal */}
      <IngredientModal
        isOpen={showAddModal}
        onClose={() => setShowAddModal(false)}
        onSubmit={handleAddIngredient}
      />

      {/* Notification */}
      {notification && (
        <div 
          className={`fixed bottom-4 right-4 p-4 rounded-lg shadow-lg ${
            notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'
          } text-white`}
        >
          {notification.message}
        </div>
      )}
    </div>
  );
};

export default Inventory;