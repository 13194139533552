import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { TrendingUp, Package, Users, DollarSign, AlertCircle, Clock, LogOut } from 'lucide-react';
import TopNavigation from '../components/TopNavigation';
import { useAuth } from '@clerk/clerk-react';

const StatCard = ({ title, value, icon: Icon, trend }) => (
  <div className="bg-white rounded-xl shadow-sm p-4 md:p-6 flex items-center">
    <div className="mr-4 bg-[#da018e] bg-opacity-10 p-2 md:p-3 rounded-full">
      <Icon className="w-4 h-4 md:w-6 md:h-6 text-[#da018e]" />
    </div>
    <div>
      <p className="text-xs md:text-sm text-gray-500">{title}</p>
      <p className="text-lg md:text-2xl font-bold text-gray-900">{value}</p>
      {trend && (
        <p className={`text-xs md:text-sm ${trend > 0 ? 'text-green-600' : 'text-red-600'}`}>
          {trend > 0 ? '↑' : '↓'} {Math.abs(trend)}%
        </p>
      )}
    </div>
  </div>
);

const Dashboard = () => {
  const { signOut } = useAuth();
  const history = useHistory();
  const [salesData, setSalesData] = useState([]);
  const [topSmoothies, setTopSmoothies] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        // Simulate API call
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        setSalesData([
          { date: '2024-01-01', total: 1200 },
          { date: '2024-01-02', total: 1900 },
          { date: '2024-01-03', total: 1600 },
          { date: '2024-01-04', total: 2100 },
          { date: '2024-01-05', total: 2400 },
          { date: '2024-01-06', total: 1800 },
          { date: '2024-01-07', total: 2200 }
        ]);

        setTopSmoothies([
          { name: 'Berry Blast', sales_count: 145 },
          { name: 'Tropical Paradise', sales_count: 132 },
          { name: 'Green Energy', sales_count: 128 },
          { name: 'Protein Power', sales_count: 112 },
          { name: 'Mango Tango', sales_count: 98 }
        ]);

        setSchedule([
          { time: '9:00 AM', title: 'Morning Prep' },
          { time: '11:00 AM', title: 'Rush Hour Prep' },
          { time: '2:00 PM', title: 'Inventory Check' },
          { time: '4:00 PM', title: 'Clean Equipment' }
        ]);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  const handleLogout = async () => {
    await signOut();
    history.push('/');
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="text-2xl font-bold text-[#da018e]">Loading...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <TopNavigation />
      <div className="p-4 md:p-6 max-w-7xl mx-auto">
        <div className="mb-6 md:mb-8">
          <div className="flex justify-between items-start">
            <div>
              <h1 className="text-2xl md:text-4xl font-bold text-gray-900 mb-2">Analytics Dashboard</h1>
              <p className="text-lg md:text-xl text-[#da018e]">Track your SmoothieApp performance</p>
            </div>
            <button
              onClick={handleLogout}
              className="bg-[#da018e] text-white px-4 py-2 rounded-lg flex items-center hover:bg-[#b8017a] transition-colors"
            >
              <LogOut className="w-4 h-4 mr-2" />
              Logout
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6 mb-6 md:mb-8">
          <StatCard title="Total Revenue" value="$12,426" icon={DollarSign} trend={8.2} />
          <StatCard title="Total Sales" value="1,234" icon={Package} trend={-2.1} />
          <StatCard title="Active Users" value="892" icon={Users} trend={5.7} />
          <StatCard title="Conversion Rate" value="3.5%" icon={TrendingUp} trend={1.2} />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 md:gap-8">
          <div className="lg:col-span-2">
            <div className="bg-white rounded-xl shadow-sm p-4 md:p-6">
              <h2 className="text-xl md:text-2xl font-bold text-gray-900 mb-4">Sales Overview</h2>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={salesData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="total" stroke="#da018e" strokeWidth={2} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="space-y-6">
            <div className="bg-white rounded-xl shadow-sm p-4 md:p-6">
              <h2 className="text-xl md:text-2xl font-bold text-gray-900 mb-4">Top Selling Smoothies</h2>
              <div className="space-y-4">
                {topSmoothies.map((smoothie, index) => (
                  <div key={index} className="flex items-center justify-between">
                    <span className="text-sm md:text-base text-gray-700">{smoothie.name}</span>
                    <span className="text-sm md:text-base font-medium text-gray-900">{smoothie.sales_count} sales</span>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-sm p-4 md:p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg md:text-xl font-semibold text-gray-900">Today's Schedule</h2>
              </div>
              <div className="space-y-3">
                {schedule.map((event, index) => (
                  <div key={index} className="flex items-center gap-3 p-2 md:p-3 bg-gray-50 rounded-lg">
                    <Clock className="w-4 h-4 text-[#da018e]" />
                    <div>
                      <p className="text-xs md:text-sm font-medium text-gray-700">{event.title}</p>
                      <p className="text-xs text-gray-500">{event.time}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-3 md:p-4 rounded-lg">
              <div className="flex">
                <AlertCircle className="text-yellow-400 mr-2" />
                <p className="text-xs md:text-sm text-yellow-700">
                  <span className="font-bold">Tip:</span> Monitor your top-selling items to optimize inventory.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

