import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ArrowLeft, Search, Book, Bell, UserCircle, ChevronLeft, ChevronRight } from 'lucide-react';

const SkeletonLoader = ({ className }) => (
  <div className={`bg-gray-200 rounded ${className}`}></div>
);

const SMOOTHIE_DATA = [
  { name: "All Shook Up", ingredients: { small: "3 sweetener, 3 banana, 1 black peanut butter, 1 blue + 1 green MRP, 2 tsp. cocoa", large: "5 sweetener, 4 banana, 1.5 black peanut butter, 2 green MRP, 3 tsp. cocoa" } },
  { name: "Amazon Kick", ingredients: { small: "1 water, 3 sweetener, 1 ivory strawberry, 1 blue blueberry, 1.5 banana, 2 blue acai", large: "1 water, 4 sweetener, 1 gray strawberry, 1 ivory blueberry, 2 banana, 3 blue acai" } },
  { name: "Big Bang", ingredients: { small: "2.5 sweetener, 1 gray strawberry, 1.5 banana, 6 vanilla, 1 blue whey protein", large: "3.5 sweetener, 2 ivory strawberry, 2 banana, 8 vanilla, 1 blue whey protein" } },
  { name: "Bubble Trouble", ingredients: { small: "2 sweetener, 1/2 orange juice, 1/2 lemon juice, 1 ivory strawberry, 1 blue mango, 1 red mango boba", large: "3 sweetener, 1 orange juice, 1 lemon juice, 1 gray strawberry, 1 ivory mango, 1 red mango boba" } },
  { name: "Captain Kid", ingredients: { small: "1 water, 2 sweetener, 2 ivory strawberry, 2 blue orange sherbet", large: "2 water, 4 sweetener, 2 gray strawberry, 3 blue orange sherbet" } },
  { name: "Chocolate Chimp", ingredients: { small: "4 sweetener, 3 banana, 4 vanilla, 1.5 honey (dime size), 2 tsp. cocoa, 1 blue whey protein", large: "5 sweetener, 5 banana, 6 vanilla, 2 honey (dime size), 3 tsp. cocoa, 1 blue whey protein" } },
  { name: "Chocolate Elvis", ingredients: { small: "1 water, 4 sweetener, 2 banana, 2 blue frozen yogurt, 1 black peanut butter, 2 tsp. cocoa, 1 tbsp. nonfat milk, 1 energy blast", large: "2 water, 5 sweetener, 3 banana, 3 blue frozen yogurt, 1.5 black peanut butter, 3 tsp. cocoa, 1.5 tbsp. nonfat milk, 1 energy blast" } },
  { name: "Cookie Crusher", ingredients: { small: "1 water, 3 sweetener, 2 blue frozen yogurt, 0.5 cup Oreo cookie, 1 blue+1 green MRP", large: "2 water, 4 sweetener, 3 blue frozen yogurt, 0.75 cup Oreo cookie, 2 green MRP" } },
  { name: "Dragon Fruit Lemon Splash", ingredients: { small: "4 sweetener, 1 lemon juice, 1 ivory strawberry, 1 blue pineapple, 1 blue dragon fruit", large: "5 sweetener, 1.5 lemon juice, 1 gray strawberry, 1 ivory pineapple, 2 blue dragon fruit" } },
  { name: "Fiji Island Breeze", ingredients: { small: "2.5 sweetener, 2 coconut water, 1.5 passion fruit, 1 blue pineapple, 1 blue mango, 1.5 banana, 1 leafy greens", large: "3.5 sweetener, 3 coconut water, 2 passion fruit, 1 ivory pineapple, 1 ivory mango, 2 banana, 2 leafy greens" } },
  { name: "Get Up & Glow", ingredients: { small: "1 oatmilk, 3 sweetener, 2 banana, 2 blue frozen yogurt, 1 black peanut butter, 2 tsp. coffee, 2 tsp. cocoa, 1 tbsp. granola, 1 collagen peptides", large: "2 oatmilk, 4 sweetener, 3 banana, 3 blue frozen yogurt, 1.5 black peanut butter, 3 tsp. coffee, 3 tsp. cocoa, 1.5 tbsp. granola, 1 collagen peptides" } },
  { name: "Java The Nut", ingredients: { small: "1 water, 3 sweetener, 2 blue frozen yogurt, 1 black peanut butter, 2 tsp. coffee, 2 tsp. cocoa, 1 tbsp. nonfat milk", large: "2 water, 4 sweetener, 3 blue frozen yogurt, 1.5 black peanut butter, 3 tsp. coffee, 3 tsp. cocoa, 1.5 tbsp. nonfat milk" } },
  { name: "Lean Green Extreme", ingredients: { small: "3 sweetener, 3 orange juice, 0.5 lemon juice, 1 blue pineapple, 1 blue mango, 1.5 banana, 1 leafy greens, 1 blue plant-based protein", large: "4 sweetener, 4 orange juice, 0.75 lemon juice, 1 ivory pineapple, 1 ivory mango, 2 banana, 2 leafy greens, 1 blue plant-based protein" } },
  { name: "Lunar Lemonade", ingredients: { small: "3 sweetener, 1.5 lemon juice, 1 blue strawberry, 2 banana", large: "4 sweetener, 2 lemon juice, 1 ivory strawberry, 3 banana" } },
  { name: "Mango Passion", ingredients: { small: "1 water, 1 sweetener, 4 passion fruit, 1 ivory strawberry, 1 ivory mango, 1 energy blast", large: "1.5 water, 1.5 sweetener, 6 passion fruit, 1 gray strawberry, 1 gray mango, 1 energy blast" } },
  { name: "Mountain Man Crunch", ingredients: { small: "2 water, 3 sweetener, 2 banana, 2 blue frozen yogurt, 1.5 black peanut butter, 1.5 honey (dime size), 1 tbsp. granola", large: "3 water, 4 sweetener, 3 banana, 3 blue frozen yogurt, 2 black peanut butter, 2 honey (dime size), 1.5 tbsp. granola" } },
  { name: "Mr. Mongo", ingredients: { small: "2 sweetener, 1 gray strawberry, 1.5 banana, 2 blue frozen yogurt, 4 vanilla, 1 tbsp. nonfat milk, 1 blue whey protein", large: "3 sweetener, 2 ivory strawberry, 2 banana, 3 blue frozen yogurt, 6 vanilla, 1.5 tbsp. nonfat milk, 1 blue whey protein" } },
  { name: "PB Blues", ingredients: { small: "3 sweetener, 1 ivory blueberry, 1 banana, 1 black peanut butter, 1 blue+1 green MRP", large: "4 sweetener, 1 gray blueberry, 2 banana, 1.5 black peanut butter, 2 green MRP" } },
  { name: "PB Dragon Fruit Riptide", ingredients: { small: "3 sweetener, 1 blue strawberry, 1 banana, 1 blue dragon fruit, 1 blue frozen yogurt, 1 black peanut butter, 1/4 cup oats, 2 tsp. chia seeds", large: "4 sweetener, 1 ivory strawberry, 2 banana, 2 blue dragon fruit, 1.5 blue frozen yogurt, 1.5 black peanut butter, 1/4 cup oats, 3 tsp. chia seeds" } },
  { name: "PBJ", ingredients: { small: "2 sweetener, 1 gray strawberry, 1.5 banana, 2 blue frozen yogurt, 1.5 black peanut butter, 4 vanilla, 1 tbsp. nonfat milk", large: "3 sweetener, 2 ivory strawberry, 2 banana, 3 blue frozen yogurt, 2 black peanut butter, 6 vanilla, 1.5 tbsp. nonfat milk" } },
  { name: "Peanut Butter Power Up", ingredients: { small: "2 water, 3 sweetener, 2 banana, 2 blue frozen yogurt, 1.5 black peanut butter, 1/4 cup oats, 2 tsp. chia seeds", large: "3 water, 4 sweetener, 3 banana, 3 blue frozen yogurt, 2 black peanut butter, 1/4 cup oats, 2 tsp. chia seeds" } },
  { name: "Peanut Butter Punch", ingredients: { small: "3 sweetener, 3 banana, 1 black peanut butter, 1 blue+1 green MRP", large: "4 sweetener, 4 banana, 1.5 black peanut butter, 2 green MRP" } },
  { name: "Rio Berry Bliss", ingredients: { small: "1 sweetener, 1 coconut water, 1 ivory strawberry, 1 blue raspberry, 1 blue pineapple, 2 blue acai", large: "1.5 sweetener, 2 coconut water, 1 gray strawberry, 1 ivory raspberry, 1 ivory pineapple, 4 blue acai" } },
  { name: "Road Runner", ingredients: { small: "1 water, 1 sweetener, 1 orange juice, 1 ivory strawberry, 1 blue raspberry, 1 blue blueberry, 1 energy blast", large: "1 water, 1.5 sweetener, 2 orange juice, 1 gray strawberry, 1 ivory raspberry, 1 ivory blueberry, 1 energy blast" } },
  { name: "Shag-A-Delic", ingredients: { small: "2 sweetener, 1 ivory strawberry, 1 blue blueberry, 1.5 banana, 2 blue frozen yogurt, 4 vanilla, 1 tbsp. nonfat milk, 1 energy blast", large: "3 sweetener, 1 gray strawberry, 1 ivory blueberry, 2 banana, 3 blue frozen yogurt, 6 vanilla, 1.5 tbsp. nonfat milk, 1 energy blast" } },
  { name: "Strawberry Colada", ingredients: { small: "3 sweetener, 1 ivory strawberry, 1 blue pineapple, 2 tbsp. coconut, 4 vanilla, 1 tbsp. nonfat milk", large: "4 sweetener, 1 gray strawberry, 1 ivory pineapple, 3 tbsp. coconut, 6 vanilla, 1.5 tbsp. nonfat milk" } },
  { name: "Strawberry Super Squeeze", ingredients: { small: "2 sweetener, 1 gray strawberry, 1 banana, 1 blue+1 green MRP", large: "3 sweetener, 2 ivory strawberry, 2 banana, 2 green MRP" } },
  { name: "The Last Mango", ingredients: { small: "1 water, 2 sweetener, 1 ivory mango, 1 ivory peach, 1 banana, 2 blue orange sherbet", large: "2 water, 3 sweetener, 1 gray mango, 1 gray peach, 2 banana, 3 blue orange sherbet" } },
  { name: "Twig & Berries", ingredients: { small: "2 sweetener, 1 gray strawberry, 1 banana, 2 blue frozen yogurt", large: "3 sweetener, 2 ivory strawberry, 2 banana, 3 blue frozen yogurt" } },
  { name: "Vinnie Del Rocco", ingredients: { small: "2 water, 2 sweetener, 1 blue raspberry, 1 blue blueberry, 1 ivory strawberry, 2 blue orange sherbet", large: "2 water, 3 sweetener, 1 ivory raspberry, 1 ivory blueberry, 1 gray strawberry, 3 blue orange sherbet" } },
];

const HeaderIcon = ({ icon: Icon, onClick }) => (
  <button 
    onClick={onClick}
    className="text-[#da018e] p-2 hover:bg-gray-200 rounded-full transition-colors min-h-[44px] min-w-[44px]"
  >
    <Icon className="w-6 h-6" />
  </button>
);

const Card = ({ title, subtitle, onClick, loading, isFavorite }) => {
  if (loading) {
    return <SkeletonLoader className="h-44 w-full" />;
  }

  return (
    <div 
      className={`rounded-lg p-6 cursor-pointer shadow-md hover:shadow-lg transition-shadow border border-gray-200 min-h-[44px] min-w-[44px] ${
        isFavorite ? 'bg-gradient-to-br from-[#d9058d] to-[#ff6b9b] text-white' : 'bg-white text-black'
      }`}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <h2 className="text-2xl font-bold mb-4 font-sans">{title}</h2>
      <p className={`text-sm font-sans ${isFavorite ? 'text-white' : 'text-gray-600'}`}>{subtitle}</p>
    </div>
  );
};

const CustomerFavorites = ({ favorites, onSelect, loading }) => {
  return (
    <div className="mb-8">
      <h2 className="text-2xl font-bold mb-4 text-black font-sans">Customer Favorites</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {loading ? (
          Array(4).fill().map((_, index) => (
            <SkeletonLoader key={index} className="h-44 w-full" />
          ))
        ) : (
          favorites.filter(Boolean).slice(0, 4).map((smoothie, index) => (
            <Card 
              key={index}
              title={smoothie.name}
              subtitle="Customer Favorite"
              onClick={() => onSelect(smoothie)}
              loading={loading}
              isFavorite={true}
            />
          ))
        )}
      </div>
    </div>
  );
};

const RecipeCard = ({ smoothie, onClose }) => {
  const [selectedSize, setSelectedSize] = useState('small');
  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    { title: 'Ingredients', content: (
      <div className="space-y-3">
        <h2 className="text-2xl font-semibold text-black">Ingredients:</h2>
        <ul className="list-none pl-0 space-y-2 text-lg">
          {smoothie.ingredients[selectedSize].split(', ').map((ingredient, index) => (
            <li key={index} className="flex items-start">
              <span className="inline-block w-2 h-2 rounded-full bg-[#da018e] mt-2 mr-2"></span>
              <span>{ingredient}</span>
            </li>
          ))}
        </ul>
      </div>
    )},
    { title: 'Nutrition', content: (
      <div className="space-y-3">
        <h2 className="text-2xl font-semibold text-black">Nutrition Facts:</h2>
        <p className="text-lg">Calories: 350</p>
        <p className="text-lg">Protein: 15g</p>
        <p className="text-lg">Carbs: 45g</p>
        <p className="text-lg">Fat: 10g</p>
      </div>
    )},
    { title: 'Allergens', content: (
      <div className="space-y-3">
        <h2 className="text-2xl font-semibold text-black">Allergen Information:</h2>
        <p className="text-lg">Contains: Milk, Soy</p>
        <p className="text-lg">May contain traces of nuts</p>
      </div>
    )}
  ];

  return (
    <div className="bg-white text-black p-6 font-sans">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-black">{smoothie.name}</h1>
        <button 
          onClick={onClose}
          className="text-[#da018e] hover:bg-gray-200 rounded-full p-2 transition-colors"
        >
          <ArrowLeft className="w-6 h-6" />
        </button>
      </div>

      <div className="flex justify-center space-x-4 mb-6">
        <button 
          className={`border-2 border-[#da018e] rounded-full px-6 py-3 text-lg font-semibold min-h-[44px] min-w-[44px] ${selectedSize === 'small' ? 'bg-[#da018e] text-white' : 'text-[#da018e]'}`}
          onClick={() => setSelectedSize('small')}
        >
          20oz
        </button>
        <button 
          className={`border-2 border-[#da018e] rounded-full px-6 py-3 text-lg font-semibold min-h-[44px] min-w-[44px] ${selectedSize === 'large' ? 'bg-[#da018e] text-white' : 'text-[#da018e]'}`}
          onClick={() => setSelectedSize('large')}
        >
          32oz
        </button>
      </div>

      <div className="bg-gray-100 p-6 rounded-lg mb-6">
        {steps[currentStep].content}
      </div>

      <div className="flex justify-between items-center">
        <button
          onClick={() => setCurrentStep(prev => Math.max(0, prev - 1))}
          className="text-[#da018e] hover:bg-gray-200 rounded-full p-2 transition-colors"
          disabled={currentStep === 0}
        >
          <ChevronLeft className="w-6 h-6" />
        </button>
        <div className="flex space-x-2">
          {steps.map((_, index) => (
            <div
              key={index}
              className={`w-2 h-2 rounded-full ${index === currentStep ? 'bg-[#da018e]' : 'bg-gray-300'}`}
            />
          ))}
        </div>
        <button
          onClick={() => setCurrentStep(prev => Math.min(steps.length - 1, prev + 1))}
          className="text-[#da018e] hover:bg-gray-200 rounded-full p-2 transition-colors"
          disabled={currentStep === steps.length - 1}
        >
          <ChevronRight className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

export default function SmoothieApp() {
  const navigate = useRouteMatch();
  const observer = useRef();

  const [searchText, setSearchText] = useState('');
  const [selectedLetter, setSelectedLetter] = useState(null);
  const [selectedSmoothie, setSelectedSmoothie] = useState(null);
  const [clickCounts, setClickCounts] = useState({});
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showNotifications, setShowNotifications] = useState(false);

  const groupedSmoothies = useMemo(() => {
    return SMOOTHIE_DATA.reduce((acc, smoothie) => {
      const firstLetter = smoothie.name[0].toUpperCase();
      if (!acc[firstLetter]) {
        acc[firstLetter] = [];
      }
      acc[firstLetter].push(smoothie);
      return acc;
    }, {});
  }, []);

  const filteredSmoothies = useMemo(() => {
    return SMOOTHIE_DATA.filter(smoothie =>
      smoothie.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText]);

  const handleBack = () => {
    if (selectedSmoothie) {
      setSelectedSmoothie(null);
    } else {
      navigate('/smoothieapp');
    }
  };

  const handleTrainingMode = () => {
    navigate('/training');
  };

  const handleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const handleAccountDetails = () => {
    navigate('/dashboard');
  };

  const handleLetterSelect = (letter) => {
    setSelectedLetter(letter);
    setSelectedSmoothie(null);
    setPage(1);
    setHasMore(true);
    setLoading(true);
    setTimeout(() => setLoading(false), 500);
  };

  const handleSmoothieSelect = (smoothie) => {
    setSelectedSmoothie(smoothie);
    updateClickCount(smoothie.name);
  };

  const updateClickCount = (smoothieName) => {
    const now = Date.now();
    const updatedCounts = { ...clickCounts };
    if (!updatedCounts[smoothieName]) {
      updatedCounts[smoothieName] = [];
    }
    updatedCounts[smoothieName] = [
      ...updatedCounts[smoothieName].filter(click => now - click < 8 * 60 * 60 * 1000),
      now
    ];
    setClickCounts(updatedCounts);
    localStorage.setItem('clickCounts', JSON.stringify(updatedCounts));
  };

  const getMostClickedSmoothies = useCallback(() => {
    const now = Date.now();
    const clickCountsLast8Hours = Object.entries(clickCounts).reduce((acc, [name, clicks]) => {
      acc[name] = clicks.filter(click => now - click < 8 * 60 * 60 * 1000).length;
      return acc;
    }, {});
    return Object.entries(clickCountsLast8Hours)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 4)
      .map(([name]) => SMOOTHIE_DATA.find(smoothie => smoothie.name === name))
      .filter(Boolean);
  }, [clickCounts]);

  const lastSmoothieElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  useEffect(() => {
    const storedClickCounts = localStorage.getItem('clickCounts');
    if (storedClickCounts) {
      setClickCounts(JSON.parse(storedClickCounts));
    }
    setTimeout(() => setLoading(false), 1000);
  }, []);

  useEffect(() => {
    setSelectedLetter(null);
    setSelectedSmoothie(null);
    setPage(1);
    setHasMore(true);
  }, [searchText]);

  return (
    <div className="min-h-screen bg-gray-100 text-black font-sans p-6">
      <header className="flex justify-between items-center mb-8">
        <HeaderIcon icon={ArrowLeft} onClick={handleBack} />
        <img 
          src="https://upload.wikimedia.org/wikipedia/commons/6/6c/Planet_Smoothie_logo.svg" 
          alt="Planet Smoothie Logo" 
          className="h-12"
        />
        <div className="flex items-center space-x-2">
          <HeaderIcon icon={Bell} onClick={handleNotifications} />
          <HeaderIcon icon={UserCircle} onClick={handleAccountDetails} />
          <HeaderIcon icon={Book} onClick={handleTrainingMode} />
        </div>
      </header>

      <div className="mb-8 relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        <input
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search for a smoothie..."
          className="pl-10 pr-4 py-2 w-full bg-white rounded-full text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#da018e]/50 min-h-[44px] shadow-sm"
        />
      </div>

      {!selectedSmoothie ? (
        <>
          <CustomerFavorites 
            favorites={getMostClickedSmoothies()} 
            onSelect={handleSmoothieSelect}
            loading={loading}
          />

          {!searchText && !selectedLetter && (
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
              {Object.entries(groupedSmoothies).map(([letter, smoothies]) => (
                <Card 
                  key={letter}
                  title={letter}
                  subtitle={`${smoothies.length} smoothie${smoothies.length !== 1 ? 's' : ''}`}
                  onClick={() => handleLetterSelect(letter)}
                  loading={loading}
                />
              ))}
            </div>
          )}

          {(searchText || selectedLetter) && (
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
              {(searchText ? filteredSmoothies : groupedSmoothies[selectedLetter] || [])
                .slice(0, page * 10)
                .map((smoothie, index) => (
                  <div 
                    key={index}
                    ref={index === ((page * 10) - 1) ? lastSmoothieElementRef : null}
                  >
                    <Card 
                      title={smoothie.name}
                      subtitle="View Recipe"
                      onClick={() => 

 handleSmoothieSelect(smoothie)}
                      loading={loading}
                    />
                  </div>
                ))}
            </div>
          )}
        </>
      ) : (
        <RecipeCard 
          smoothie={selectedSmoothie} 
          onClose={handleBack}
        />
      )}

      <footer className="mt-12 text-center text-sm text-gray-600 font-sans">
        © 2024 Planet Smoothie. All rights reserved.
      </footer>
    </div>
  );
}